
import {
  defineComponent,
  computed,
  ref,
  watch,
  onMounted,
  PropType,
} from "vue";

type AlignType = "left" | "center" | "right";

export default defineComponent({
  name: "Input",
  props: {
    label: {
      type: String,
      default: "",
    },
    labelWidth: {
      type: String,
      default: "80px",
    },

    modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    validate: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    border: {
      type: Boolean,
      default: true,
    },
    textAlign: {
      type: String as PropType<AlignType>,
      default: "right",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    const input = ref<HTMLInputElement | null>(null);
    const nativeInputValue = computed(() => {
      return props.modelValue === null || props.modelValue === undefined
        ? ""
        : String(props.modelValue);
    });
    function setNativeInpur() {
      if (input.value) {
        if (nativeInputValue.value === input.value.value) {
          return;
        }
        input.value.value = nativeInputValue.value;
      }
    }
    watch(nativeInputValue, () => {
      setNativeInpur();
    });
    function handleInput(e: any) {
      const { value } = e.target;
      ctx.emit("inputchange", value);
      ctx.emit("update:modelValue", value);
    }
    onMounted(() => {
      setNativeInpur();
    });

    return {
      handleInput,
      input,
    };
  },
});
