

interface Button {
    size: string;
}


const useButton = (props: Button) => {
    const buttonSize = props.size;
    return {
        buttonSize,
    };
};

export default useButton