<template>
  <div
    class="form-item"
    :class="[
      {
        'is-label': label,
        'is-border': border,
      },
    ]"
    :style="{ paddingLeft: label ? labelWidth : 0 }"
  >
    <label :for="name">
      <div
        class="form-label"
        :style="{ width: labelWidth }"
        :class="validate ? 'valid' : ''"
        v-if="label"
      >
        {{ label }}
      </div>
      <textarea
        ref="input"
        v-if="type == 'textarea'"
        :name="name"
        :disabled="disable"
        :placeholder="placeholder"
        @input="handleInput"
      >
      </textarea>
      <input
        v-else
        ref="input"
        :type="type"
        :name="name"
        :disabled="disable"
        :placeholder="placeholder"
        @input="handleInput"
        :style="{ textAlign: textAlign }"
      />
    </label>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  watch,
  onMounted,
  PropType,
} from "vue";

type AlignType = "left" | "center" | "right";

export default defineComponent({
  name: "Input",
  props: {
    label: {
      type: String,
      default: "",
    },
    labelWidth: {
      type: String,
      default: "80px",
    },

    modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    validate: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    border: {
      type: Boolean,
      default: true,
    },
    textAlign: {
      type: String as PropType<AlignType>,
      default: "right",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    const input = ref<HTMLInputElement | null>(null);
    const nativeInputValue = computed(() => {
      return props.modelValue === null || props.modelValue === undefined
        ? ""
        : String(props.modelValue);
    });
    function setNativeInpur() {
      if (input.value) {
        if (nativeInputValue.value === input.value.value) {
          return;
        }
        input.value.value = nativeInputValue.value;
      }
    }
    watch(nativeInputValue, () => {
      setNativeInpur();
    });
    function handleInput(e: any) {
      const { value } = e.target;
      ctx.emit("inputchange", value);
      ctx.emit("update:modelValue", value);
    }
    onMounted(() => {
      setNativeInpur();
    });

    return {
      handleInput,
      input,
    };
  },
});
</script>


<style lang="scss" scoped>
.form-item {
  position: relative;
  line-height: 45px;
  color: #444444;
  font-size: 13.988px;
  padding-bottom: 1px;
}
.is-border {
  border-bottom: 1px solid #eee;
}
.is-label {
  padding-left: 73.5px;
}

.form-item input {
  width: 100%;

  outline: none;
  border: none;
  padding-right: 10px;
  box-sizing: border-box;
  background-color: transparent;
}

.form-item textarea {
  width: 100%;
  display: block;
  outline: none;
  border: none;
  padding: 10px 0;
  box-sizing: border-box;
  min-height: 100px;
  font-family: Arial;
  background-color: transparent;
}

.form-item input:disabled {
  border: 1px solid transparent;
  background-color: transparent;
}

.form-label {
  width: 64px;
  position: absolute;
  left: 0;
  top: 0;
  height: 45px;
  line-height: 45px;
  text-align: left;
  padding-left: 10px;
}
.valid {
  &::after {
    color: #cc3333;
    position: absolute;
    content: "*";
    left: 0;
    top: 0;
  }
}

.form-item-textarea {
  border-bottom: 1px solid #eee;
  padding-left: 66px;
  position: relative;
  text-align: right;
  padding-bottom: 10px;
}

.form-item-textarea label {
  height: 100%;
  display: inline-block;
  width: 100%;
}
</style>