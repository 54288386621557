<template>
  <div class="change-mobile">
    <ui-input
      :label="label"
      name="tel"
      :validate="true"
      :disable="disable"
      placeholder="请输入新手机号"
      v-model="mobile"
    ></ui-input>
    <ui-code
      label="验证码"
      :validate="true"
      :tel="mobile"
      name="code"
      v-model="code"
      ref="uiCode"
    ></ui-code>
    <ui-button class="submit" @handleclick="onSubmit" type="submit">保存</ui-button
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import UiInput from "@/components/UI/Input";
import UiCode from "@/components/UI/Code";
import UiButton from "@/components/UI/Button";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import qs from "qs";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "ChangeMobile",
  components: {
    UiInput,
    UiCode,
    UiButton,
  },
  setup() {
    const label = ref("原手机号");
    const mobile = ref("");
    const code = ref("");
    const oldCode = ref("");
    const disable = ref(true);
    const uiCode = ref({});
     const router = useRouter()
    axios
      .get("/M/User/ChangeMobile", {
        params: {
          validate: true,
        },
      })
      .then((res) => {
        if (res.data.success) {
          mobile.value = res.data.obj.my.mobile;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log('err')
      });

    // 提交

    function checkCode(code: string) {
      const reg = /^[0-9]{6}$/;
      console.log(code);
      if (!code || !reg.test(code)) {
        Toast({
          type: "warning",
          title: "请输入正确的验证码",
        });
        return false;
      }

      return true;
    }
    function checkTel(tel: string) {
      if (tel == "") {
        Toast({
          type: "warning",
          title: "请输入手机号码",
        });
        return false;
      }
      const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
      if (tel.length != 11 || !retel.test(tel)) {
        Toast({
          type: "warning",
          title: "请输入正确的手机号码",
        });
        return false;
      }
      return true;
    }
    function checkMobile() {
      if (!checkCode(code.value)) {
        return false;
      }
      const data = qs.stringify({
        beforeTel: mobile.value,
        pcode: code.value,
      });
      axios
        .post("/M/Server/DoChangeMobile", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            oldCode.value = code.value;
            label.value = "新手机号";
            code.value = "";
            mobile.value = "";
            disable.value = false;
            (uiCode.value as any).clearCode();
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }
    function checkAfterMobile() {
      if (!checkTel(mobile.value) || !checkCode(code.value)) {
        return false;
      }
      const data = qs.stringify({
        tel: mobile.value,
        code: code.value,
        beforesms: oldCode.value,
      });
      axios
        .post("/M/Server/DoChangeNewMobile", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                localStorage.removeItem("token");
                router.push({
                  name: "Mine",
                });
                
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }
    function onSubmit() {
      // url:'/M/Server/DoChangeMobile',
      if (!oldCode.value) {
        checkMobile();
      } else {
        checkAfterMobile();
      }
    }

    return {
      mobile,
      code,
      disable,
      onSubmit,
      label,
      uiCode,
    };
  },
});
</script>

<style lang="scss" scoped>
.change-mobile {
  padding: 0 10px;
}
.submit {
  margin-top: 40px;
}
</style>