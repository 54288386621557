<template>
  <div class="code-wapper">
    <div class="input-module">
      <ui-input
        :label="label"
        :name="name"
        type="text"
        :validate="validate"
        v-model="code"
        :placeholder="placeholder"
        @input="handleInput"
      ></ui-input>
    </div>
    <div class="button">
      <ui-button
        size="mini"
        :style="{ width: '90px', paddingRight: '0px', paddingLeft: '0px' }"
        @click="getEmailCode"
        plain
        >{{ codeText }}</ui-button
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import Button from "../../Button/src/Button.vue";
import UiInput from "../../Input";
import UiButton from "@/components/UI/Button";
import Toast from "@/components/UI/Toast/";
import axios from "@/api/axios";
export default defineComponent({
  name: "Code",
  components: {
    UiInput,
    UiButton,
  },
  props: {
    label: {
      type: String,
      default: "Code",
    },
    email: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    validate: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: "obtain",
    },
    placeholder: {
      type: String,
      default: "E-mail verification code",
    },
  },
  setup(props, ctx) {
    const code = ref("");
    function handleInput(e: any) {
      code.value = code.value.slice(0, 6);
      ctx.emit("update:modelValue", code.value);
    }

    let sendCodeFlag = true;
    let count = 60;
    const codeText = ref(props.btnText);
    let timer = 0;
    function checkEmail(email: string) {
      if (email === "") {
        Toast({
          title: "Please fill in your email",
          type: "warning",
        });
        return false;
      }
      const reEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!reEmail.test(email)) {
        Toast({
          title: "Please enter the correct mailbox",
          type: "warning",
        });
        return false;
      }
      return true;
    }

    function clearCode() {
      code.value = "";
      sendCodeFlag = true;
      count = 60;
      clearInterval(timer);
      timer = 0;

      codeText.value = "Resend";
    }

    //获取验证码
    function getEmailCode() {
      if (!checkEmail(props.email) || !sendCodeFlag) {
        return false;
      }

      sendCodeFlag = false;
      /**
       *  获取验证码
       * */
      const formData = new FormData();
      formData.append("eml", props.email);
      formData.append("option", "getcode");
      axios
        .post("/M/Server/GetEMLCode", formData)
        .then((res) => {
          if (res.data.success) {
            Toast({
              title: res.data.msg,
              type: "success",
            });
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //     title: '未知错误，请联系客服',
          //     type: "success"
          // })
        });

      timer = setInterval(function () {
        if (count == 0) {
          clearInterval(timer);
          clearCode();
          sendCodeFlag = true;
          codeText.value = "Resend";
        } else {
          codeText.value = count + "s";
        }
        count--;
      }, 1000);
    }

    return {
      handleInput,
      codeText,
      code,
      getEmailCode,
      clearCode,
    };
  },
});
</script>


<style lang="scss" scoped>
.code-wapper {
  display: flex;
  justify-content: space-between;
  .input-module {
    flex: 2.5;
  }
  .button {
    flex: 1;
    background: transparent;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
